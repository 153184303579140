import { ModalForm } from "./modal_form_controller"

export default class extends ModalForm {
  static targets = ["firstName", "lastName", "businessOwnershipPercentage", "validatableInput"]

  connect(){
    super.connect();
    this._bindBusinessOwnershipPercentageChange();
    this._displayError();
  }

  _bindBusinessOwnershipPercentageChange(){
    let ownershipPercentageInput = this.element.querySelector('.business-ownership-field input.coapplicant_business_ownership_percentage');
    let ownershipTitleInput = this.element.querySelector('.applicant-business-ownership-title-field input[name*="[business_ownership_title]"]');

    $(ownershipPercentageInput).on('change', function(event){
      if(parseInt(ownershipPercentageInput.value) == 0 ){
        showGuarantorTitle(ownershipTitleInput);
      }else{
        hideGuarantorTitle(ownershipTitleInput);
      }
    });
  }

  remove(){
    let _this = this;
    $('.business-ownership-form-container').one('cocoon:after-remove', function(e, removedItem, originalEvent){
      _this.modalView.hide();
      updateOwnershipNotice();
    });
  }

  _afterSummaryUpdate() {
    $(this.element).find('.coapplicant-summary-content').removeClass('error');
  }

  _displayError() {
    if($(this.element).find('.label.error').length) {
      $(this.element).find('.coapplicant-summary-content').addClass('error');
    }
  }

  get summaryFields() {
    return ["firstName", "lastName", "businessOwnershipPercentage"];
  }
}
