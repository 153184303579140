import { Controller } from "stimulus";

export default class extends Controller {
  connect(){
    var bestInPlaceEl = this.element.querySelectorAll(".best_in_place");
    $(bestInPlaceEl).best_in_place();
    $(bestInPlaceEl).bind('ajax:error', function(_, {responseText}) {
      toastr.error(JSON.parse(responseText).join('\n'));
    })
    setupInputmasks();
    $(".bip-inputmask-span").on("click", function(e) {
      window.setTimeout(function() {
          var phoneNumberInput = $(e.target).find("input[name='phone_number']");
          phoneNumberInput.inputmask('999-999-9999', {
              removeMaskOnSubmit: true,
              autoUnmask: true
          });
      }, 100);
    });

    bindMessageClose();
  }
}
