import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["allowedActionButtons", "batchUpdateIds"];
  static values = {
    selectedDocumentids: { type: Array, default: [] }
  }

  connect(){
    $('#documents-and-references-section-body .menu .item').tab();

    configureDocumentsAccordion();
    this._showToastrMessage();
    setupSidebar($("#add-custom-document"));
  }

  documentAccordionConnected(event){
    var accordionEl = event.target;
    var uploadForm = accordionEl.querySelector(".file-upload-form");
    var previewFileUploadEl = accordionEl.querySelectorAll(".preview-file-upload");
    var deleteFileUploadEl = accordionEl.querySelectorAll("a.delete-file-upload-action");
    var bestInPlaceEl = accordionEl.querySelectorAll(".best_in_place");
    var circlePopupEl = accordionEl.querySelector(".title i.icon");

    $(circlePopupEl).popup();
    $(bestInPlaceEl).best_in_place();
    configureFileUpload(uploadForm);

    this._bindFileUploadPreviewModal(previewFileUploadEl);
    bindMessageClose();
    this._bindFileUploadDelete(deleteFileUploadEl);
  }

  handleNewFileUpload(event){
    var previewFileUploadEl = event.target.querySelector(".preview-file-upload");
    var deleteFileUploadEl = event.target.querySelectorAll("a.delete-file-upload-action");

    this._bindFileUploadPreviewModal(previewFileUploadEl);
    this._bindFileUploadDelete(deleteFileUploadEl);
  }

  _bindFileUploadDelete(deleteFileUploadEl){
    $(deleteFileUploadEl).on('click', async function(event){
      event.preventDefault();

      var accordionEl = $(event.target).closest('.document-accordion');
      var accordionUrl = $(accordionEl).data('document-accordion-url');
      var dropboxUrl = $(accordionEl).data('document-dropbox-url');
      var documentId = $(accordionEl).data('document');
      var turboFrame = document.querySelector(`turbo-frame[id*="document_${documentId}"]`);
      var dropboxFrame = document.querySelector(`turbo-frame[id*="loan_application_document_dropbox"]`);

      try {
        const response = await fetch(event.target.href, {
          method: 'DELETE',
          headers: {
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            'Content-Type': 'application/json'
          }
        })

        if(response.status === 200){
          const data = await response.json();
          toastr.success(data.message);
        } else if(response.status === 599 || response.status === 401){
          const data = await response.json();
          toastr.error(data.message);
        }

        $(turboFrame).attr('src', accordionUrl); // refresh document accordion
        $(dropboxFrame).attr('src', dropboxUrl); // refresh document dropbox
      }catch(error){
        toastr.error("Error deleting file");
      }
    });
  }

  _toggleDocumentActionButtons(){
    let availableDocumentActions = [];
    $("input[name='document_ids[]']:checked").map(function() {
      availableDocumentActions.push($(this).data('documents-tab-permitted-events-on-document'));
    })
    let activeDocumentActions = (availableDocumentActions.length !== 0) ? availableDocumentActions.reduce((a, b) => a.filter(c => b.includes(c))) : [];

    $('.allowed-actions :button[name=commit]').prop('disabled', true);

    activeDocumentActions.forEach(function(action) {
      $("button[value=" + action + "]").prop('disabled', false);
    })
  }

  documentCheckboxHandler(event){
    this._toggleDocumentActionButtons();
    let documentAccordionEl = $(event.target).closest('.document-accordion-container').find('.document-accordion');

    if(event.target.checked){
      this.selectedDocumentidsValue = [...this.selectedDocumentidsValue, event.target.value];
      documentAccordionEl.addClass("checked");
    } else {
      this.selectedDocumentidsValue = this.selectedDocumentidsValue.filter((documentId) => { return documentId !== event.target.value});
      documentAccordionEl.removeClass("checked");
    }

    this.batchUpdateIdsTarget.value = JSON.stringify(this.selectedDocumentidsValue);
  }

  _bindFileUploadPreviewModal(previewFileUploadEl){
    $(previewFileUploadEl).on("click", function () {
      var fileUploadId = $(this).data("file-upload-id");
      var fileUploadPreviewModal = $('#loan-application-documents-tab .file-upload-preview[data-file-upload-id="' + fileUploadId + '"]');

      fileUploadPreviewModal.modal({
        detachable: false,
        onShow: function(){
          var previewFileUploadContainer = this.closest('.file-upload-preview-container');
          $(previewFileUploadContainer).removeClass('hidden');
          $(this).removeClass('legacy');
          $(previewFileUploadContainer).addClass('visible');
        },
        onHide: function(){
          var previewFileUploadContainer = this.closest('.file-upload-preview-container');
          $(previewFileUploadContainer).removeClass('visible');
          $(previewFileUploadContainer).addClass('hidden');
        }
      }).modal('show');
    });
  }

  _showToastrMessage(){
    let message = $('#documents').data('document-tab-toastr-message');
    if(!message) { return }
    let action = Object.values(message)[0].action;
    let successDocuments = [];
    let errorDocuments = [];
    Object.keys(message).forEach(document => { if(message[document].succeeded) { successDocuments.push(message[document].document_name) }});
    Object.keys(message).forEach(document => { if(!message[document].succeeded) { errorDocuments.push(message[document].document_name) }});

    if(successDocuments && successDocuments['length']) { toastr.success("The following documents were successfully " + this._getDisplayabeAction(action) + this._getDisplayableDocumentList(successDocuments)); }
    if(errorDocuments && errorDocuments['length']) { toastr.error("The following documents could not be " + this._getDisplayabeAction(action) + this._getDisplayableDocumentList(errorDocuments)); }
  }

  _getDisplayableDocumentList(messages){
    let messageHtml = "<ul>";
    messages.forEach((message) => {
      messageHtml += ("<li>" + message + "</li>");
    })
    messageHtml += "</ul>";
    return messageHtml;
  }

  _getDisplayabeAction(action){
    let availableActions = {
      'waive_off': 'waived off:',
      'resubmit': 'resubmitted:',
      'submit': 'submitted:',
      'accept': 'accepted:',
      'reject': 'rejected:'
    }

    return availableActions[action];
  }
}
